import moment from 'moment'

export const formatDate = (date, format = "DD/MM/YYYY") => {
    if (!date) { return '' }
    return moment(date).format(format)
}

export const addDays = (date, days) => {
    if (!date) { return date }
    return moment(date).add(days, 'days');
}

export const addMonths = (date, months) => {
    if (!date) { return date }
    return moment(date).add(months, 'months');
}

export const formatDateTime = (date, addSeconds = false) => {
    if (!date) { return '' }
    return moment(date).format(`DD/MM/YYYY HH:mm${addSeconds ? ':ss' : ''}`)
}


export const chatTimeCount = (data) => {
    if (!data) {
        return ''
    }
    const agora = moment();
    const dataMensagem = moment(data);
    const diferenca = moment.duration(agora.diff(dataMensagem));
    
    if (diferenca.asMinutes() < 1) {
        return 'Menos de 1 minuto atrás';
    } else if (diferenca.asMinutes() < 60) {
        const minutos = Math.floor(diferenca.asMinutes());
        return `${minutos} minuto${minutos > 1 ? 's' : ''} atrás`;
    } else if (diferenca.asHours() < 24) {
        const horas = Math.floor(diferenca.asHours());
        return `${horas} hora${horas > 1 ? 's' : ''} atrás`;
    } else if (diferenca.asDays() < 7) {
        const dias = Math.floor(diferenca.asDays());
        return `${dias} dia${dias > 1 ? 's' : ''} atrás`;
    } else if (diferenca.asWeeks() < 4) {
        const semanas = Math.floor(diferenca.asWeeks());
        return `${semanas} semana${semanas > 1 ? 's' : ''} atrás`;
    } else if (diferenca.asMonths() < 12) {
        return `Mais de 1 mês atrás`;
    } else {
        return `Mais de um ano atrás`;
    }
}

export const diasAtrasoCount = (data) => {
    if (!data) {
        return ''
    }
    const agora = moment();
    const dataMensagem = moment(data);
    const diferenca = moment.duration(agora.diff(dataMensagem));
    
    if (diferenca.asHours() < 24) {
        return `menos de 24 horas`;
    } else if (diferenca.asDays() < 7) {
        const dias = Math.floor(diferenca.asDays());
        return `${dias} dia${dias > 1 ? 's' : ''}`;
    } else if (diferenca.asWeeks() < 4) {
        const semanas = Math.floor(diferenca.asWeeks());
        return `${semanas} semana${semanas > 1 ? 's' : ''}`;
    } else if (diferenca.asMonths() < 12) {
        return `mais de 1 mês`;
    } else {
        return `mais de um ano`;
    }
}

export const formatDateTimeApi = (date) => {
    if (!date) { return null }
    return moment(date).format("YYYY-MM-DD HH:mm:ss")
}

export const firstDateOfMonth = (date = new Date()) =>
    new Date(date.getFullYear(), date.getMonth(), 1);

const addMomentFilters = (app) => {
    app.config.globalProperties.$moment = {
        formatDate: formatDate,
        formatDateTime: formatDateTime,
        chatTimeCount: chatTimeCount,
        addDays: addDays,
        addMonths: addMonths,
        diasAtrasoCount: diasAtrasoCount,
        firstDateOfMonth: firstDateOfMonth
    }
}

export default addMomentFilters
